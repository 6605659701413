
import {defineComponent, ref, computed, onMounted} from "vue";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";

import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {libEnums} from "@/core/appcore/libs/Lib_Enums";
import {dateConvert} from "@/core/filters/datatime";

import AutoComplete from "@/components/appcomps/forms/AutoComplete.vue"
import MasterConfig from "@/core/config/MasterConfig";

import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"

export default defineComponent({
    name: "Make-Reservation-Search",
    components: {
        AutoComplete,
    },
    props: {
        idCode: String,
        searchType: String,
        idLabel: String,
    },
    setup(props, {emit}) {

        let theSelecteds = {
            AccountID: "",  AirportID: "", HotelID: "", HotelKind: "", MarketID: "", TripType: "",
            PaxADT: 1, PaxCHD: 0,
            TransferIncluded: 1,
        };

        let dtValStart = ref(""); let dtValFinish = ref("");
        let dtValChd1 = ref(""); let dtValChd2 = ref(""); let dtValChd3 = ref(""); let dtValChd4 = ref("");

        let fpickFromDate;
        let fpickReturnDate;

        const refBtnSearch = ref<null | HTMLButtonElement>(null);

        onMounted(()=>{
            initDatePickers();
            initMarket();
            initTripType();
            showChildDates_By_Count(0);

            if (props.idCode===libEnums.SearchType.Package) {
                //sendTestValues();
            }

        });

        function initDatePickers() {
            let myConfigFrom = {"locale": Turkish, dateFormat: "d-m-Y (D)", minDate: "today",};
            fpickFromDate = flatpickr("#srcFromDate_"+props.idCode, myConfigFrom);
            (fpickFromDate as any).config.onChange.push(changeFlatPicker_FromDate);

            initReturnDatePricker(new Date());

            let myDtConfig_Chd = {"locale": Turkish, dateFormat: "d-m-Y (D)",};

            //let vvFlatPickr_PaxChd1 = flatpickr("#inpPaxCHD1_"+props.idCode, myDtConfig_Chd);
            //let vvFlatPickr_PaxChd2 = flatpickr("#inpPaxCHD2_"+props.idCode, myDtConfig_Chd);
            //let vvFlatPickr_PaxChd3 = flatpickr("#inpPaxCHD3_"+props.idCode, myDtConfig_Chd);
            //let vvFlatPickr_PaxChd4 = flatpickr("#inpPaxCHD4_"+props.idCode, myDtConfig_Chd);
        }

        function initReturnDatePricker(prmMinDate) {
            let myConfigReturn = {"locale": Turkish, dateFormat: "d-m-Y (D)", minDate: dateConvert.method.dateToflatpicker(prmMinDate),};
            fpickReturnDate = flatpickr("#srcReturnDate_"+props.idCode, myConfigReturn);
        }

        function changeFlatPicker_FromDate() {
            let vvDate = (fpickFromDate as any).selectedDates[0];
            //console.log("changeFlatPicker_FromDate "+vvDate);
            initReturnDatePricker((fpickFromDate as any).selectedDates[0]);
        }

        function initMarket() {
            let selMarket = document.getElementById("sel_SearchStep1_Market_"+props.idCode) as HTMLInputElement;
            if (selMarket!==undefined && selMarket!==null) {
                selMarket.value = "";
            }
        }

        function initTripType() {

            //TripType
            if (props.searchType===libEnums.SearchType.Package || props.searchType===libEnums.SearchType.Hotel  || props.searchType===libEnums.SearchType.Transfer) {
                let rowTripType = document.getElementById("rowTripType_"+props.idCode) as HTMLElement;
                if (rowTripType!==null) {
                    rowTripType.style.display = "none";
                }
            }

            //AirportsFrom
            if (props.searchType===libEnums.SearchType.Hotel) {
                let rowAirportsFrom = document.getElementById("rowAirportsFrom_"+props.idCode) as HTMLElement;
                if (rowAirportsFrom!==null) {
                    rowAirportsFrom.style.display = "none";
                }
            }

            //TransferIncluded
            if (props.searchType===libEnums.SearchType.Hotel) {
                let rowTransferIncluded = document.getElementById("rowTransferIncluded_"+props.idCode) as HTMLElement;
                if (rowTransferIncluded!==null) {
                    rowTransferIncluded.style.display = "";
                }
            }
        }

        function showChildDates_By_Count(prmChildCount) {
            for (let i=1; i<MasterConfig.search.maxCHD+1; i++) {
                let elPaxChd = document.getElementById("inpPaxCHD"+i+"_"+props.idCode);
                if (elPaxChd!==null) {
                    if (i<=prmChildCount) {
                        elPaxChd.style.visibility = "";
                    } else {
                        elPaxChd.style.visibility = "hidden";
                    }
                }
                let divPaxCHDIinputs = document.getElementById("divPaxCHDIinputs_"+props.idCode);
                if (divPaxCHDIinputs!==null) {
                    divPaxCHDIinputs.style.height=Number((prmChildCount * 50)+10) + "px";
                }
            }
        }

        function getChildDates_By_Count(prmChildCount) {
            let rv = [];
            if (1<=prmChildCount) rv.push(dtValChd1.value===""? "" : dateConvert.method.convertToDateYM(dtValChd1.value));
            if (2<=prmChildCount) rv.push(dtValChd2.value===""? "" : dateConvert.method.convertToDateYM(dtValChd2.value));
            if (3<=prmChildCount) rv.push(dtValChd3.value===""? "" : dateConvert.method.convertToDateYM(dtValChd3.value));
            if (4<=prmChildCount) rv.push(dtValChd4.value===""? "" : dateConvert.method.convertToDateYM(dtValChd4.value));
            //rv.sort().reverse();
            rv.sort();
            return rv;
        }

        function change_InpAdultCount() {
            let inpAdultCount = document.getElementById("inpAdultCount_"+props.idCode) as HTMLInputElement;
            if (inpAdultCount!==null) {
                let vvValue = Number(inpAdultCount.value);
                theSelecteds.PaxADT = vvValue;
                console.log("change_InpAdultCount "+vvValue);
            }
            emit("thePaxAdt", theSelecteds.PaxADT);
        }

        function change_InpChildCount() {
            let inpChildCount = document.getElementById("inpChildCount_"+props.idCode) as HTMLInputElement;
            if (inpChildCount!==null) {
                let vvValue = Number(inpChildCount.value);
                showChildDates_By_Count(vvValue);
                theSelecteds.PaxCHD = vvValue;
                console.log("change_InpChildCount "+vvValue);
            }
        }

        //### Accounts
        function keyupAutoCompAccounts(prmInputValue) {
            //console.log("keyupAutoCompAccounts prmInputValue: "+prmInputValue);
            if (prmInputValue.length == 3) {
                store.dispatch(Actions.ACCOUNT_LIST_ALL, { 'Title': prmInputValue});
            }
        };

        function selectItemAutoCompAccounts(prmItem) {
            console.log("selectItemAutoCompAccounts prmItem: "+prmItem.id+" "+prmItem.name);
            theSelecteds.AccountID = prmItem.id;
        };

        function itemProjectionAccounts (prmItem) {
            //console.log("itemProjectionAccounts id: "+prmItem.id+" "+prmItem.name);
            return prmItem.name;
        };

        const dataAccountList = computed(() => {
            let dataComputed = store.getters.accountList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsAccounts = computed(() => {
            let arrTmp: unknown[] = [];
            if (dataAccountList.value!==undefined && dataAccountList.value.data !== undefined) {
                for (var each of dataAccountList.value.data) {
                    arrTmp.push({"id": each.ID, "name": each.Title});
                }
            }
            return arrTmp;
        });

        //### Market
        const lstMarketsList = computed(() => {
            return store.getters.MarketsListCache;
        });

        function change_Market() {
            let selMarket = document.getElementById("sel_SearchStep1_Market_"+props.idCode) as HTMLInputElement;
            if (selMarket!==null) {
                let vvValue = selMarket.value;
                theSelecteds.MarketID = vvValue;
            }
        }

        //### Search Type
        function change_TripType() {
            let selTripType = document.getElementById("sel_SearchStep1_TripType_"+props.idCode) as HTMLInputElement;
            if (selTripType!==null) {
                let vvValue = selTripType.value;
                theSelecteds.TripType = vvValue;
            }
        }

        //### Transfer Included
        function change_TransferIncluded() {
            let selTransferIncluded = document.getElementById("sel_SearchStep1_TransferIncluded_"+props.idCode) as HTMLInputElement;
            if (selTransferIncluded!==null) {
                let vvValue = selTransferIncluded.value;
                theSelecteds.TransferIncluded = Number(vvValue);
            }
        }

        //## Airports (From)
        function keyupAutoCompAirportsFrom(prmInputValue) {
            //console.log("keyupAutoCompAirportsFrom prmInputValue: "+prmInputValue);
            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "FROM", 'IATACode': prmInputValue.toUpperCase()};
                //console.log("keyupAutoCompAirportsFrom A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "FROM", 'Title': prmInputValue};
                //console.log("keyupAutoCompAirportsFrom SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        };

        function selectItemAutoCompAirportsFrom(prmItem) {
            theSelecteds.AirportID = prmItem.id;
        };

        function itemProjectionAirportsFrom (prmItem) {
            return prmItem.name;
        };

        const lstAutoCompleteItemsAirportsFrom = computed(() => {
          let arrTmp: unknown[] = [];
          if (dataAirportsList.value!==undefined && dataAirportsList.value.data !== undefined) {
            for (var each of dataAirportsList.value.data) {
              arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
            }
          }
          return arrTmp;
        });


      //## Airports (To)
      function keyupAutoCompAirportsTo(prmInputValue) {
        //console.log("keyupAutoCompAirportsFrom prmInputValue: "+prmInputValue);
        let myPayload = {};
        if (prmInputValue.length === 3) {
          myPayload = {'CallFrom': "TO", 'IATACode': prmInputValue.toUpperCase()};
          //console.log("keyupAutoCompAirportsFrom A SENDING myPayload: "+JSON.stringify(myPayload));
          store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
        } else if (prmInputValue.length === 4) {
          myPayload = {'CallFrom': "TO", 'Title': prmInputValue};
          //console.log("keyupAutoCompAirportsFrom SENDING B myPayload: "+JSON.stringify(myPayload));
          store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
        }
      };

      function selectItemAutoCompAirportsTo(prmItem) {
        theSelecteds.AirportID = prmItem.id;
      };

      function itemProjectionAirportsTo (prmItem) {
        return prmItem.name;
      };

      const lstAutoCompleteItemsAirportsTo = computed(() => {
        let arrTmp: unknown[] = [];
        if (dataAirportsList.value!==undefined && dataAirportsList.value.data !== undefined) {
          for (var each of dataAirportsList.value.data) {
            arrTmp.push({"id": each.IATACode, "name": "("+each.IATACode+") "+ each.Title});
          }
        }
        return arrTmp;
      });

        const dataAirportsList = computed(() => {
            let dataComputed = store.getters.airportsList;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });



        //##Hotels (To)
        function keyupAutoCompHotels(prmInputValue) {

            let myPayload = {};
            if (prmInputValue.length === 3) {
                myPayload = {'CallFrom': "TO", 'Title': prmInputValue.toUpperCase()}
                console.log("keyupAutoCompHotels A SENDING myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.HOTEL_LIST_ALL_TO, myPayload);
            }
            /*
            else if (prmInputValue.length === 4) {
                myPayload = {'CallFrom': "TO", 'Title': prmInputValue}
                console.log("keyupAutoCompHotels SENDING B myPayload: "+JSON.stringify(myPayload));
                store.dispatch(Actions.HOTEL_LIST_ALL_TO, myPayload);
            }
            */
        };

        function selectItemAutoCompHotels(prmItem) {
            //console.log("selectItemAutoCompHotels prmItem: "+prmItem.id+" "+prmItem.name+" "+prmItem.kind);
            theSelecteds.HotelID = prmItem.id;
            theSelecteds.HotelKind = prmItem.kind;
        };

        function itemProjectionHotels (prmItem) {
            //console.log("itemProjectionHotels id: "+prmItem.id+" "+prmItem.name);
            return prmItem.name;
        };

        const dataHotelList = computed(() => {
            let dataComputed = store.getters.hotelListTo;
            if (dataComputed!==undefined) {
                return dataComputed;
            }
            return {data:[]};
        });

        const lstAutoCompleteItemsHotels = computed(() => {
            //console.log("lstAutoCompleteItemsHotels START dataHotelList: "+JSON.stringify(dataHotelList.value));
            let arrTmp: unknown[] = [];
            if (dataHotelList.value!==undefined && dataHotelList.value.data !== undefined) {
                //console.log("lstAutoCompleteItemsHotels v1")
                for (var each of dataHotelList.value.data) {
                    //console.log("lstAutoCompleteItemsHotels each "+each.ID+" "+each.Title);
                    arrTmp.push({"id": each.ID, "name": "("+each.Kind+") "+ each.Title, "kind": each.Kind});
                }
            }
            //console.log("lstAutoCompleteItemsHotels END arrTmp: "+JSON.stringify(arrTmp));
            return arrTmp;
        });

        //### Search
        function checkForSearch() {
            if (theSelecteds.AccountID==="") {
                libTools.method.swalError("Tamam", "Cari Hesap Seçiniz");
                return false;
            }
            if (theSelecteds.MarketID==="") {
                libTools.method.swalError("Tamam", "Market Seçiniz");
                return false;
            }
            if (props.searchType===libEnums.SearchType.Package || props.searchType===libEnums.SearchType.Flight) {
                if (theSelecteds.AirportID==="") {
                    libTools.method.swalError("Tamam", "Havaalanı Seçiniz (Nereden)");
                    return false;
                }
            }
            if (theSelecteds.HotelID==="") {
                libTools.method.swalError("Tamam", "Otel Seçiniz (Nereye)");
                return false;
            }
            if (theSelecteds.PaxADT===0 || !Number.isInteger(theSelecteds.PaxADT)) {
                libTools.method.swalError("Tamam", "Yetişkin kişi sayısı girmelisiniz");
                return false;
            }
            if (dtValStart.value==="") {
                libTools.method.swalError("Tamam", "Gidiş Tarihi girmelisiniz");
                return false;
            }
            if (dtValFinish.value==="") {
                libTools.method.swalError("Tamam", "Dönüş Tarihi girmelisiniz");
                return false;
            }

            return true;
        }

        function click_SearchPackage() {
            //console.log("click_SearchPackage START theSelecteds: "+JSON.stringify(theSelecteds));
          //AppCore.prsCore_Flight.resetSearchParams();
            if (checkForSearch()) {

                console.log("click_SearchPackage AppCore.prsCore_Flight.ccvSearchProcessID: "+AppCore.prsCore_Flight.ccvSearchProcessID)
                AppCore.prsCore_Flight.ccvSearchProcessID+=1;
                AppCore.prsCore_Flight.ccvSearchProcessID2+=1;


                let vvPayload = {
                    Type: props.idCode.toUpperCase(),   //PACKAGE - HOTEL - TRANSFER
                    SearchType: theSelecteds.HotelKind,  //HOTEL - REGION
                    AccountCode: theSelecteds.AccountID,
                    From: theSelecteds.AirportID,
                    To: theSelecteds.HotelID,
                    StartDate: dtValStart.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValStart.value),
                    FinishDate: dtValFinish.value===""? "" : dateConvert.method.flatpickerToDateYM(dtValFinish.value),
                    Pax: {
                        ADT: theSelecteds.PaxADT,
                        CHD: theSelecteds.PaxCHD,
                        CHDBirthDates: getChildDates_By_Count(theSelecteds.PaxCHD),
                    },
                    Market: theSelecteds.MarketID,
                    TransferIncluded: theSelecteds.TransferIncluded,
                    Type2: props.idCode.toUpperCase(),
                    SearchProcessID: AppCore.prsCore_Flight.ccvSearchProcessID,
                    SearchProcessID2: AppCore.prsCore_Flight.ccvSearchProcessID2,
                    TestSearchCode: "TestSearchCode1a",
                };
                console.log("click_SearchPackage v1 vvPayload: "+JSON.stringify(vvPayload));
                refBtnSearch.value.disabled = true;
                refBtnSearch.value.setAttribute("data-kt-indicator", "on");
                AppCore.prsCore_Flight.resetStepsBasket();
                emit("on-search-started", props.searchType);
                store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, true);
                store.dispatch(Actions.FLIGHT_RESERVATION_LIST, vvPayload);

                //store.dispatch(Actions.API_SEARCH, vvPayload);
            }
        }

        function searchFinished() {
            refBtnSearch.value.disabled = false;
            refBtnSearch.value.setAttribute("data-kt-indicator", "off");
            //console.log("Search.vue searchFinished");
        }

        function sendTestValues() {
            let vvPayload = {
                "Type": "PACKAGE",
                "SearchType": "HOTEL",
                "AccountCode": "1",
                "From": "",
                "To": "23",
                "StartDate": "2023-12-20",
                "FinishDate": "2023-12-25",
                "Pax": {
                    "ADT": 1,
                    "CHD": 0,
                    "CHDBirthDates": [

                    ]
                },
                "Market": "1",
                "Type2": props.idCode.toUpperCase(),
            };

            console.log("sendTestValues v1 vvPayload: "+JSON.stringify(vvPayload));
            refBtnSearch.value.disabled = true;
            refBtnSearch.value.setAttribute("data-kt-indicator", "on");
            AppCore.prsCore_Flight.resetStepsBasket();
            emit("on-search-started", props.searchType);
            store.dispatch(Actions.FLIGHT_RESERVATION_IS_SEARCHING, true);
            store.dispatch(Actions.FLIGHT_RESERVATION_LIST, vvPayload);
        }

        return {
            libTools, libEnums,
            MasterConfig,
            dtValStart, dtValFinish, dtValChd1, dtValChd2, dtValChd3, dtValChd4,
            refBtnSearch,
            change_InpChildCount, change_InpAdultCount,
            keyupAutoCompAccounts, selectItemAutoCompAccounts, itemProjectionAccounts, lstAutoCompleteItemsAccounts,
            lstMarketsList, change_Market,
            change_TransferIncluded,
            change_TripType,
            keyupAutoCompAirportsFrom, selectItemAutoCompAirportsFrom, itemProjectionAirportsFrom, lstAutoCompleteItemsAirportsFrom,
            keyupAutoCompAirportsTo, selectItemAutoCompAirportsTo, itemProjectionAirportsTo, lstAutoCompleteItemsAirportsTo,
            lstAutoCompleteItemsHotels, itemProjectionHotels, keyupAutoCompHotels, selectItemAutoCompHotels,
            click_SearchPackage,
            searchFinished,
        };
    }
});
